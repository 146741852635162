<template>
  <v-container>
    <p
      class="section_titles"
      v-text="section_title"
    />
    <v-card
      v-if="!load_projects"
      class="about_wrapper">
      <!-- start project table -->
        <v-data-table 
          id="projects_table"
          light 
          :headers="headers" 
          :items="projects" 
          item-key="_id"
        >
            <template 
              v-slot:item="props">
              <tr>
                <td
                  class="text-left">
                  <p 
                    v-text="props.item._id"
                    class="text_content"
                  />
                </td>
                <td
                  class="text-left">
                  <p 
                    v-text="props.item.name"
                    class="text_content"
                  />
                </td>
                <td
                  class="text-left">
                  <p 
                    v-text="props.item.category.name"
                    class="text_content"
                  />
                </td>
                <td
                  class="text-center">
                  <v-layout
                    row>
                    <v-btn
                      @click="set_edit_project(props.item)"
                      :loading="load_edit"
                      small
                      icon
                      text>
                      <v-icon
                        color="black">
                        {{view_icon}}
                      </v-icon>
                    </v-btn>
                    <v-btn
                      @click="set_add_image(props.item)"
                      small
                      icon
                      text>
                      <v-icon
                        color="black">
                        {{images_icon}}
                      </v-icon>
                    </v-btn>
                    <v-btn
                      @click="set_edit_images(props.item)"
                      small
                      icon
                      text>
                      <v-icon
                        color="black">
                        {{edit_images_icon}}
                      </v-icon>
                    </v-btn>
                    <v-btn  
                      @click="set_delete_project(props.item)"
                      small
                      icon
                      text>
                      <v-icon
                        color="black">
                        {{delete_icon}}
                      </v-icon>
                    </v-btn>
                  </v-layout>
                </td>
              </tr>
            </template>
        </v-data-table>
      <!-- end project table -->
    </v-card>
    <v-card
      v-else
      flat
      color="transparent">
      <v-container
        style="margin-top: 20%">
        <v-layout
          row
          justify-center>
          <loading-animation />
        </v-layout>
      </v-container>
    </v-card>
    <!-- start add project btn -->
      <div
        class="btn_send_wrapper">
        <v-tooltip
          bottom>
          <template 
            v-slot:activator="{ on }"
          >
            <span 
              v-on="on">
              <v-btn
                style="background-color: white"
                :disabled="load_projects"
                @click="add_project"
                large
                class="btn_submit"
                icon>
                <v-icon
                  color="primary"
                  v-text="sumbitIcon"
                />
              </v-btn>
            </span>
          </template>
          <span
            v-text="'Agregar un proyecto'"
          />
        </v-tooltip>
      </div>
    <!-- end add project btn -->
    <!-- start add project dialog -->
      <v-dialog
        overlay-color="primary"
        overlay-opacity=".97"
        v-model="add_project_modal"
        max-width="73%">
        <v-card
          style="background-color: rgba(240, 240, 240, .85); padding: 5%">
          <v-container>
            <v-layout
              row
              justify-center>
              <p
                style="font-size: 20px !important; font-weight: normal !important"
                class="section_titles"
                v-text="'Agregar un proyecto'"
              />
            </v-layout>
            <v-layout
              row
              wrap
              justify-center>
              <v-flex
                class="flex_projects"
                xs12
                sm6>
                <span>
                  <v-select
                    :items="categories"
                    append-icon="mdi-apps"
                    background-color="white"
                    label="Categoría"
                    v-model="project_add.category"
                    color="primary"
                    outlined
                    item-text="name"
                    item-value="_id"
                  />
                </span>
              </v-flex>
              <v-flex
                class="flex_projects"
                xs12
                sm6>
                <v-text-field
                  append-icon="mdi-sign-text"
                  background-color="white"
                  label="Nombre"
                  v-model="project_add.name"
                  color="primary"
                  outlined
                />
              </v-flex>
              <v-flex
                class="flex_projects"
                xs12
                sm6>
                <v-textarea
                  append-icon="mdi-format-align-left"
                  background-color="white"
                  label="Descripción - Español"
                  v-model="project_add.esDescription"
                  color="primary"
                  outlined
                />
              </v-flex>
              <v-flex
                class="flex_projects"
                xs12
                sm6>
                <v-textarea
                  append-icon="mdi-format-align-left"
                  background-color="white"
                  label="Descripción - Inglés"
                  v-model="project_add.enDescription"
                  color="primary"
                  outlined
                />
              </v-flex>
              <v-flex
                class="flex_projects"
                xs12
                sm6>
                <v-text-field
                  append-icon="mdi-map-marker-circle"
                  background-color="white"
                  label="LAT"
                  v-model="project_add.lat"
                  color="primary"
                  outlined
                />
              </v-flex>
              <v-flex
                class="flex_projects"
                xs12
                sm6>
                <v-text-field
                  append-icon="mdi-map-marker-circle"
                  background-color="white"
                  label="LON"
                  v-model="project_add.lon"
                  color="primary"
                  outlined
                />
              </v-flex>
              <v-flex
                class="flex_projects"
                xs12
                sm6>
                <v-text-field
                  append-icon="mdi-play-box-outline"
                  background-color="white"
                  label="Video"
                  v-model="project_add.video"
                  color="primary"
                  outlined
                />
              </v-flex>
            </v-layout>
            <v-layout
              row
              justify-center>
              <v-layout
                row
                justify-center
                style="max-width: 50%">
                <v-btn
                  @click="add_project_modal = false"
                  large
                  dark
                  color="primary">
                  Cancelar
                </v-btn>
                <v-spacer/>
                <v-btn
                  :loading="load_add"
                  :disables="load_add"
                  @click="confirm_add"
                  large
                  dark
                  color="primary">
                  Confirmar
                </v-btn>
              </v-layout>
            </v-layout>
          </v-container>
        </v-card>
      </v-dialog>
    <!-- end add project dialog -->
    <add-image 
      :projectId="add_image_id"
      :bActive="add_image"
      :usr_token="usr_token"
      @close="add_image = false"
    />
    <edit-project
      :bActive="edit_project"
      :projectEdit="project_to_edit"
      :categories="categories"
      :usr_token="usr_token"
      @update="get_all_projects()"
      @close="edit_project = false"
    />
    <delete-project
      :bActive="delete_project"
      :project="project_to_delete"
      :usr_token="usr_token"
      @update="get_all_projects()"
      @close="delete_project = false"
    />
    <edit-images
      :urls="project_images_edit.urls"
      :bActive="edit_images"
      :usr_token="usr_token"
      :project="project_images_edit"
      @close="edit_images = false"
      @update="get_all_projects"
    />
  </v-container>
</template>
<script>
export default {
  data: () => ({
    add_image_id: '',
    add_image: false,
    delete_icon: 'mdi-delete',
    view_icon: 'mdi-eye',
    images_icon: 'mdi-image-plus',
    edit_images_icon: 'mdi-image-edit',
    headers: [
      {
        text: 'ID',
        value: '_id',
        sortable: false
      },
      {
        text: 'Nombre',
        value: 'name',
        sortable: false
      },
      {
        text: 'Categoría',
        value: 'category',
        sortable: false
      },
      {
        text: 'Acciones',
        align: 'center',
        sortable: false
      }
    ],
    project_add: {
      name: '',
      category: '',
      esDescription: '',
      enDescription: '',
      lat: null,
      lon: null,
      video: ''
    },
    load_projects: false,
    sumbitIcon: 'mdi-plus',
    about: {},
    section_title: 'Proyectos',
    add_project_modal: false,
    categories: [],
    load_add: false,
    projects: [],
    project_to_edit: {},
    edit_project: false,
    load_edit: false,
    project_to_delete: {},
    delete_project: false,
    project_images_edit: {},
    edit_images: false
  }),
  mounted () {
    this.get_all_projects()
    this.get_categories()
  },
  computed: {
    usr_token () {
      return 'Bearer ' + this.$store.state.token
    }
  },
  components: {
    AddImage: () => import('@/components/sections/Admin/Projects/AddImage'),
    EditProject: () => import('@/components/sections/Admin/Projects/EditProject'),
    DeleteProject: () => import('@/components/sections/Admin/Projects/DeleteProject'),
    EditImages: () => import('@/components/sections/Admin/Projects/EditImages')
  },
  methods: {
    set_edit_images (project) {
      this.project_images_edit = project
      this.edit_images = true
    },
    set_edit_project (project) {
      this.load_edit = true
      db.get(`${uri}/api/v1/projects/` + project._id)
      .then((response) => {
        this.load_edit = false
        this.project_to_edit = response.data.project
        this.edit_project = true
      })
      .catch(() => {
        this.load_edit = false
        this.$store.commit('toggle_alert', {color: 'fail', text: 'Hubo un error con la conexión. Favor de intentarlo más tarde.'})
      })
    },
    set_add_image (project) {
      this.add_image_id = project._id
      this.add_image = true
    },
    set_delete_project (project) {
      this.project_to_delete = project
      this.delete_project = true
    },  
    confirm_add () {
      this.load_add = true
      var project = new URLSearchParams()
      project.append('name', this.project_add.name)
      project.append('esDescription', this.project_add.esDescription)
      project.append('enDescription', this.project_add.enDescription)
      project.append('lat', parseFloat(this.project_add.lat))
      project.append('lon',  parseFloat(this.project_add.lon))
      project.append('category', this.project_add.category)
      project.append('video', this.project_add.video)
      db.post(`${uri}/api/v1/projects`, project, {
        headers: {
          'Authorization': this.usr_token,
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
        }
      })
        .then((response) => {
          this.load_add = false
          this.$store.commit('toggle_alert', {color: 'success', text: "Se agregó el proyecto correctamente."})
        })
        .catch((error) => {
          this.load_add = false
          this.$store.commit('toggle_alert', {color: 'fail', text: error.response.data.message})
        })
    },
    add_project () {
      this.add_project_modal = true
    },
    get_categories () {
      this.load_categories = true
      db.get(`${uri}/api/v1/categories`, {})
      .then((response) => {
        this.categories = response.data.categories
        this.load_categories = false
      })
      .catch(() => {
        this.load_categories = false
        this.$store.commit('toggle_alert', {color: 'fail', text: 'Hubo un error con la conexión. Favor de intentarlo más tarde.'})
      })
    },
    get_projects_category () {
      this.load_projects = true
      db.get(`${uri}/api/v1/projects`, {
        params: {
          category: this.active_category._id
        }
      })
      .then((response) => {
        this.load_projects = false
        this.projects = response.data.projects
      })
      .catch(() => {
        this.load_projects = false
        this.$store.commit('toggle_alert', {color: 'fail', text: 'Hubo un error con la conexión. Favor de intentarlo más tarde.'})
      })
    },
    get_all_projects () {
      this.load_projects = true
      db.get(`${uri}/api/v1/projects`, {})
      .then((response) => {
        this.load_projects = false
        this.projects = response.data.projects
      })
      .catch(() => {
        this.load_projects = false
        this.$store.commit('toggle_alert', {color: 'fail', text: 'Hubo un error con la conexión. Favor de intentarlo más tarde.'})
      })
    }
  }
}
</script>
<style scoped>
.btn_submit {
  margin: 0px !important
}
.flex_projects {
  max-width: 50%;
  padding: 5px;
}
.btn_send_wrapper {
  -webkit-box-shadow: 14px 11px 35px -3px rgba(0,0,0,0.75);
  -moz-box-shadow: 14px 11px 35px -3px rgba(0,0,0,0.75);
  box-shadow: 14px 11px 35px -3px rgba(0,0,0,0.75); 
  border-radius: 100%;
  border-style: solid;
  border-color: #21345C;
  z-index: 10;
  position:fixed;
  bottom:0;
  right:0;
  margin-right: 5%;
  margin-bottom: 5%
}
.about_wrapper {
  background-color: transparent;
  width: 100%
}
.text_content {
  margin: 5px;
  font-family: 'Open sans', sans-serif
}
</style>